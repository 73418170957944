@mixin our-pac-defaults {
  .pac-container {
    min-height: 48px;
    padding-top: 6px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
  }

  .pac-item {
    cursor: pointer;
    border-top: none;
    min-height: 48px;
    padding: 6px 16px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: flex;
    align-items: center;
  }

  .pac-icon {
    margin: 0 7px 0 0;
  }

  .pac-matched {
    font-weight: 700;
  }

  .pac-item-query {
    font-size: 1rem;
  }
}

.our-pac-dark-mode {
  @include our-pac-defaults;

  .pac-container {
    background-color: #303030;
    color: #fff;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  }

  .pac-item {
    color: #fff;
    //background-color: #303030;
    background-color: rgba(255, 255, 255, 0.02);
  }

  .pac-item:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.08);
  }

  .pac-item-selected, .pac-item-selected:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.12);
  }

  .pac-matched {
    color: #fff;
  }

  .pac-item-query {
    color: #fff;
  }

  .pac-logo:after {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-non-white3.png);
  }

  .hdpi.pac-logo:after {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-non-white3_hdpi.png)
  }

}

.our-pac-light-mode {
  @include our-pac-defaults;

  .pac-container {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  }

  .pac-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .pac-item-selected, .pac-item-selected:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
}